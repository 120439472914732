import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import img from "../images/404.png";
import Button from "../components/button";

function NotFoundPage() {
    return (
        <Layout>
            <SEO title="404: Not found" />

            <section className="bg-white dark:bg-gray-800">
                <div className="w-full max-w-6xl mx-auto px-4 py-8">
                    <div className="flex flex-col bg-gray-50 dark:bg-gray-700 items-center rounded-xl p-10">
                        <img className="block mx-auto w-1/2" src={img} />
                        <div className="p-10 text-center">
                            <h2 className="text-5xl font-bold font-display leading-none mb-6">
                                Waduh, <br /> alamat tidak ditemukan...
                            </h2>
                            <p className="text-lg mb-10">
                                Yang kamu cari tidak ditemukan mungkin salah
                                alamat, lebih baik kembali lagi atau cari jalan
                                lain.
                            </p>

                            <Button
                                size="lg"
                                color="primary"
                                url="/"
                                text="Kembali"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default NotFoundPage;
